var Leaderboard, Leaders,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Leaders = require("models/leaderboard");

Leaderboard = (function(superClass) {
  "Application Loading Controller";
  extend(Leaderboard, superClass);

  Leaderboard.prototype.el = $("#cmhr-mc-leaderboard");

  Leaderboard.prototype.Open = "open";

  Leaderboard.prototype.Closed = "closed";

  Leaderboard.prototype.LeaderboardLoading = "loading";

  Leaderboard.prototype.LeaderboardLoaded = "loaded";

  Leaderboard.prototype.station = -1;

  Leaderboard.prototype.elements = {
    "#cmhr-mc-leaderboard-entries tbody": "entries",
    "#cmhr-mc-leaderboard-frame": "frame",
    "#leaderboard-label": "heading"
  };

  Leaderboard.prototype.events = {
    "click .btn-share": "onShareButtonClick"
  };

  function Leaderboard(station) {
    this.onUpdateScore = bind(this.onUpdateScore, this);
    this.onShareButtonClick = bind(this.onShareButtonClick, this);
    this.error = bind(this.error, this);
    this.resize = bind(this.resize, this);
    this.render = bind(this.render, this);
    this.state = bind(this.state, this);
    "Render View and Init Elements and Events";
    this.station = station;
    this.el.html(require('views/leaderboard'));
    Leaderboard.__super__.constructor.apply(this, arguments);
    Leaders.bind("refresh change update destroy", this.render);
    Leaders.bind("ajaxError", this.error);
    Spine.bind("game.update.score", this.onUpdateScore);
    $(window).resize(this.resize);
  }

  Leaderboard.prototype.state = function(to) {
    "Set Component State";
    this.el.attr("data-state", to);
    switch (to) {
      case this.Open:
        Leaders.fetch({
          clear: true
        });
        this.frame.attr("data-state", this.LeaderboardLoading);
        this.el.attr("aria-hidden", "false");
        return this.heading.focus();
      case this.Closed:
        this.frame.attr("data-state", this.LeaderboardLoading);
        return this.el.attr("aria-hidden", "true");
    }
  };

  Leaderboard.prototype.render = function() {
    var e, l;
    this.log("leaderboard::render");
    try {
      l = Leaders.all().sort(Leaders.sorted);
      this.entries.html(require('views/leaderboard_entries')(l));
    } catch (_error) {
      e = _error;
      this.log(e);
    }
    this.frame.attr("data-state", this.LeaderboardLoaded);
    return this.resize();
  };

  Leaderboard.prototype.resize = function(e) {
    return this.frame.height($(window).height() - this.frame.position().top - $("#cmhr-desktop-header").height());
  };

  Leaderboard.prototype.error = function(e) {
    this.log("leaderboard::error");
    return this.log("Error: " + e);
  };

  Leaderboard.prototype.onShareButtonClick = function(e) {
    "Handle social sharing";
    var height, left, opts, top, url, width;
    e.preventDefault();
    width = 575;
    height = 400;
    left = ($(window).width() - width) / 2;
    top = ($(window).height() - height) / 2;
    url = e.currentTarget.href;
    opts = 'status=1' + ',width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;
    return window.open(url, '', opts);
  };

  Leaderboard.prototype.onUpdateScore = function(score) {
    return $(".i18n-social").attr("data-points", score);
  };

  return Leaderboard;

})(Spine.Controller);

module.exports = Leaderboard;
