var Socket,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Socket = (function(superClass) {
  "Application Loading Controller";
  extend(Socket, superClass);

  Socket.prototype.el = $("#cmhr-mc-socket");

  Socket.prototype.elements = {
    "#cmhr-mc-socket-msg": "msg",
    "#cmhr-mc-socket-status": "status"
  };

  Socket.prototype.socket = null;

  function Socket() {
    this.onSocketGameEnd = bind(this.onSocketGameEnd, this);
    this.onSocketGameQuestionAnswered = bind(this.onSocketGameQuestionAnswered, this);
    this.onSocketGameStart = bind(this.onSocketGameStart, this);
    this.onSocketGameCountdown = bind(this.onSocketGameCountdown, this);
    this.onSocketGameStatus = bind(this.onSocketGameStatus, this);
    this.onSocketGameScores = bind(this.onSocketGameScores, this);
    this.onSocketClientQueue = bind(this.onSocketClientQueue, this);
    this.onSocketGameQueue = bind(this.onSocketGameQueue, this);
    this.onSocketDisconnected = bind(this.onSocketDisconnected, this);
    this.onSocketConnected = bind(this.onSocketConnected, this);
    this.onMultiplayerRequestStatus = bind(this.onMultiplayerRequestStatus, this);
    this.onMultiplayerAnswer = bind(this.onMultiplayerAnswer, this);
    this.onMultiplayerLeave = bind(this.onMultiplayerLeave, this);
    this.onMultiplayerJoin = bind(this.onMultiplayerJoin, this);
    this.onGetScores = bind(this.onGetScores, this);
    "Render View and Init Elements and Events";
    this.socket = io.connect("10.64.127.16:3000");
    this.el.html(require('views/socket'));
    Socket.__super__.constructor.apply(this, arguments);
    this.socket.on('io.connected', this.onSocketConnected);
    this.socket.on('disconnect', this.onSocketDisconnected);
    this.socket.on('io.game.status', this.onSocketGameStatus);
    this.socket.on('io.game.queue', this.onSocketGameQueue);
    this.socket.on('io.game.client-queue', this.onSocketClientQueue);
    this.socket.on('io.game.countdown', this.onSocketGameCountdown);
    this.socket.on('io.game.start', this.onSocketGameStart);
    this.socket.on('io.game.question.answered', this.onSocketGameQuestionAnswered);
    this.socket.on('io.game.scores', this.onSocketGameScores);
    this.socket.on('io.game.end', this.onSocketGameEnd);
    Spine.bind("game.get-scores", this.onGetScores);
    Spine.bind("game.multiplayer.answer", this.onMultiplayerAnswer);
    Spine.bind("game.multiplayer.join", this.onMultiplayerJoin);
    Spine.bind("game.multiplayer.leave", this.onMultiplayerLeave);
    Spine.bind("game.multiplayer.status", this.onMultiplayerRequestStatus);
  }

  Socket.prototype.onGetScores = function(station) {
    this.log("onGetScores");
    return this.socket.emit("game.scores");
  };

  Socket.prototype.onMultiplayerJoin = function(station) {
    this.log("onMultiplayerJoin");
    Spine.trigger("socket.controlled", {
      controlled: true,
      station: station
    });
    return this.socket.emit("game.join", station);
  };

  Socket.prototype.onMultiplayerLeave = function(station) {
    this.log("onMultiplayerLeave");
    Spine.trigger("socket.controlled", {
      controlled: false,
      station: station
    });
    return this.socket.emit("game.leave", station);
  };

  Socket.prototype.onMultiplayerAnswer = function(answer) {
    this.log("onMultiplayerAnswer");
    return this.socket.emit("game.answer", answer);
  };

  Socket.prototype.onMultiplayerRequestStatus = function(msg) {
    this.log("onMultiplayerRequestStatus");
    return this.socket.emit("game.status");
  };

  Socket.prototype.onSocketConnected = function(data) {
    this.log("onSocketConnected");
    Spine.trigger("socket.connection", true);
    this.status.attr("data-status", "true");
    return this.msg.html("Connected - v2");
  };

  Socket.prototype.onSocketDisconnected = function(data) {
    this.log("onSocketDisconnected");
    Spine.trigger("socket.connection", false);
    this.status.attr("data-status", "false");
    return this.msg.html("Reestablishing Connection...");
  };

  Socket.prototype.onSocketGameQueue = function(data) {
    return Spine.trigger("socket.game.queue", data);
  };

  Socket.prototype.onSocketClientQueue = function(data) {
    "Handle Array of Connected Clients";
    this.log("onSocketClientQueue");
    return Spine.trigger("socket.game.client-queue", data);
  };

  Socket.prototype.onSocketGameScores = function(data) {
    this.log("onSocketGameScore");
    return Spine.trigger("socket.game.scores", data);
  };

  Socket.prototype.onSocketGameStatus = function(data) {
    this.log("onSocketGameStatus :: " + data['status']);
    return Spine.trigger("socket.game.status", data);
  };

  Socket.prototype.onSocketGameCountdown = function(data) {
    this.log("onSocketGameCountdown");
    return Spine.trigger("socket.game.countdown", data);
  };

  Socket.prototype.onSocketGameStart = function(data) {
    this.log("onSocketGameStart");
    return Spine.trigger("socket.game.start", data);
  };

  Socket.prototype.onSocketGameQuestionAnswered = function(data) {
    this.log("onSocketGameQuestionAnswered");
    return Spine.trigger("socket.game.question.answered", data);
  };

  Socket.prototype.onSocketGameEnd = function(data) {
    this.log("onSocketGameEnd");
    Spine.trigger("socket.controlled", {
      controlled: false,
      station: null
    });
    return Spine.trigger("socket.game.end", data);
  };

  return Socket;

})(Spine.Controller);

module.exports = Socket;
