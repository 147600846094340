var Game, Leaderboard, Question,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Question = require("models/question");

Leaderboard = require("models/leaderboard");

Game = (function(superClass) {
  "Application Loading Controller";
  extend(Game, superClass);

  Game.prototype.el = $("#cmhr-mc-game");

  Game.prototype.elements = {
    "#cmhr-mc-game-question": "gameQuestion",
    "#cmhr-mc-game-question-image-full": "gameQuestionImageFull",
    "#cmhr-mc-game-answer-overlay": "answerOverlay",
    "#cmhr-mc-game-answers": "answers",
    "#cmhr-mc-game-image img": "image",
    "#cmhr-mc-game-instructions": "instructions",
    "#cmhr-mc-game-conclusion": "conclusion",
    "#cmhr-mc-game-cell-left": "cellLeft",
    "#cmhr-mc-game-leaderboard-single": "leaderboardSingle",
    ".leaderboard-name": "leaderboardName",
    ".leaderboard-submit": "leaderboardSubmit",
    ".submit-form": "form",
    "#cmhr-mc-game-q-current": "current",
    "#cmhr-mc-game-next": "btnNext",
    "#cmhr-mc-game-results": "btnResults",
    "#cmhr-mc-game-q-total": "total",
    "#cmhr-mc-game-question-timer": "timer",
    "#cmhr-mc-game-question-countdown": "countdown"
  };

  Game.prototype.events = {
    "click #cmhr-mc-game-answers li": "onClickAnswer",
    "click #cmhr-mc-game-next": "onClickNext",
    "click #cmhr-mc-game-results": "onClickResults",
    "mouseup .leaderboard-submit": "onLeaderboardSubmit",
    "touchend .leaderboard-submit": "onLeaderboardSubmit",
    "focus .leaderboard-name": "onFocusLeaderboardName",
    "blur .leaderboard-name": "onBlurLeaderboardName",
    "keyup .leaderboard-name": "onChangeLeaderboardName"
  };

  Game.prototype.questionOffset = 0;

  Game.prototype.currentQ = 0;

  Game.prototype.score = 0;

  Game.prototype.startTime = 0;

  Game.prototype.totalTime = -1;

  Game.prototype.answerInterval = null;

  Game.prototype.answerTimeout = null;

  Game.prototype.stateTimeout = null;

  Game.prototype.intervalIntro = 8000;

  Game.prototype.intervalViewImage = 6000;

  Game.prototype.intervalQuestion = 22000;

  Game.prototype.intervalResults = 10000;

  Game.prototype.scoreIncrement = 30;

  Game.prototype.scoreTimeWeight = 10;

  Game.prototype.scoreMaxQuestionPoints = 100;

  Game.prototype.answerSeconds = 22;

  Game.prototype.answerSecondsDefault = 22;

  Game.prototype.station = -1;

  Game.prototype.socketControlled = false;

  Game.prototype.isIpad = navigator.platform.indexOf("iPad") !== -1;

  Game.prototype.Answer = "answer";

  Game.prototype.AnswerLast = "answerLast";

  Game.prototype.Closed = "closed";

  Game.prototype.Instructions = "instructions";

  Game.prototype.Open = "open";

  Game.prototype.Question = "question";

  Game.prototype.Results = "results";

  Game.prototype.ResultsOpen = "open";

  Game.prototype.ResultsSaving = "save";

  Game.prototype.ResultsOpen = "error";

  function Game(station) {
    this.resize = bind(this.resize, this);
    this.stop = bind(this.stop, this);
    this.scoreAnswer = bind(this.scoreAnswer, this);
    this.runTimer = bind(this.runTimer, this);
    this.setConclusion = bind(this.setConclusion, this);
    this.setAnswer = bind(this.setAnswer, this);
    this.reset = bind(this.reset, this);
    this.loadQuestion = bind(this.loadQuestion, this);
    this.handleAnswer = bind(this.handleAnswer, this);
    this.getTotalQuestions = bind(this.getTotalQuestions, this);
    this.getQuestion = bind(this.getQuestion, this);
    this.onSocketUpdateQueuedClients = bind(this.onSocketUpdateQueuedClients, this);
    this.onSocketScores = bind(this.onSocketScores, this);
    this.onSocketNext = bind(this.onSocketNext, this);
    this.onSocketAnswered = bind(this.onSocketAnswered, this);
    this.onSocketControlStateChange = bind(this.onSocketControlStateChange, this);
    this.onLeaderboardError = bind(this.onLeaderboardError, this);
    this.onLeaderboardSave = bind(this.onLeaderboardSave, this);
    this.onLeaderboardSubmit = bind(this.onLeaderboardSubmit, this);
    this.onChangeLeaderboardName = bind(this.onChangeLeaderboardName, this);
    this.onBlurLeaderboardName = bind(this.onBlurLeaderboardName, this);
    this.onFocusLeaderboardName = bind(this.onFocusLeaderboardName, this);
    this.onClickResults = bind(this.onClickResults, this);
    this.onClickNext = bind(this.onClickNext, this);
    this.onClickAnswer = bind(this.onClickAnswer, this);
    this.state = bind(this.state, this);
    this.station = station;
    this.el.html(require('views/game'));
    Game.__super__.constructor.apply(this, arguments);
    $("#mc-game-score-" + this.station).parent().addClass("this-player");
    Spine.bind("socket.controlled", this.onSocketControlStateChange);
    Spine.bind("socket.game.question.answered", this.onSocketAnswered);
    Spine.bind("socket.game.scores", this.onSocketScores);
    Spine.bind("socket.game.client-queue", this.onSocketUpdateQueuedClients);
    Leaderboard.bind("save", this.onLeaderboardSave);
    Leaderboard.bind("ajaxError", this.onLeaderboardError);
    $(window).resize(this.resize);
  }

  Game.prototype.state = function(to) {
    "Set the Components State (CSS Transitions)";
    var j, k, len, len1, li, ref, ref1;
    this.el.attr("data-state", to);
    switch (to) {
      case this.Open:
        "(1) Start Quiz and Open Instructions";
        this.reset();
        this.questionOffset = this.questionOffset === 1 ? 0 : 1;
        this.loadQuestion(0);
        return this.state(this.Instructions);
      case this.Instructions:
        "(2) Display Questions";
        $("#cmhr-mc-intro-single-aria").blur();
        this.instructions.attr("aria-hidden", "false");
        this.gameQuestion.attr("aria-hidden", "true");
        this.instructions.find('button').focus();
        this.resize();
        return this.stateTimeout = setTimeout((function(_this) {
          return function() {
            _this.state(_this.Question);
            return _this.startTime = Date.now() / 1000;
          };
        })(this), this.intervalIntro);
      case this.Question:
        "(3) Display Question: Start Question After (n) Seconds";
        this.gameQuestion.attr("aria-hidden", "false");
        this.instructions.attr("aria-hidden", "true");
        this.answerOverlay.attr("aria-hidden", "true");
        this.stateTimeout = setTimeout((function(_this) {
          return function() {
            return _this.runTimer(true);
          };
        })(this), this.intervalViewImage);
        if (this.el.data('aria') === false) {
          this.answerTimeout = setTimeout((function(_this) {
            return function() {
              return _this.handleAnswer(-1, true);
            };
          })(this), this.intervalViewImage + this.intervalQuestion);
        }
        this.resize();
        return this.image.focus();
      case this.Answer:
        "(4/1) Display Answer Panel";
        $('#cmhr-mc-game-next').attr("aria-hidden", "false");
        $('#cmhr-mc-game-results').attr("aria-hidden", "true");
        this.answerOverlay.attr("aria-hidden", "false");
        this.instructions.attr("aria-hidden", "true");
        this.gameQuestion.attr("aria-hidden", "true");
        ref = $("#cmhr-mc-game-answers li");
        for (j = 0, len = ref.length; j < len; j++) {
          li = ref[j];
          li.blur();
        }
        return this.answerOverlay.find('h1').focus();
      case this.AnswerLast:
        "(4/2) Handle the Last Question Answer Panel";
        $('#cmhr-mc-game-next').attr("aria-hidden", "true");
        $('#cmhr-mc-game-results').attr("aria-hidden", "false");
        this.answerOverlay.attr("aria-hidden", "false");
        this.instructions.attr("aria-hidden", "true");
        this.gameQuestion.attr("aria-hidden", "true");
        ref1 = $("#cmhr-mc-game-answers li");
        for (k = 0, len1 = ref1.length; k < len1; k++) {
          li = ref1[k];
          li.blur();
        }
        this.answerOverlay.find('h1').focus();
        return this.totalTime = Math.round((Date.now() / 1000) - this.startTime);
      case this.Results:
        "(5) Show Quiz Results";
        this.runTimer(false);
        this.setConclusion();
        Spine.trigger("nav.caption.hide");
        return this.answerOverlay.find('h1').focus();
      case this.Closed:
        "Close the Game";
        Spine.trigger("game.multiplayer.leave", this.station);
        Spine.trigger("nav.caption.hide");
        return this.stop();
    }
  };

  Game.prototype.onClickAnswer = function(el) {
    "When an Answer is Selected Score and/or Show Final Results";
    var rank;
    rank = parseInt($(el.currentTarget).attr("data-rank"));
    return this.handleAnswer(rank, false);
  };

  Game.prototype.onClickNext = function(el) {
    this.loadQuestion(this.currentQ + 1);
    this.state(this.Question);
    return ga('send', 'event', 'button', 'click', 'next-question-' + this.currentQ + 1);
  };

  Game.prototype.onClickResults = function(el) {
    return this.state(this.Results);
  };

  Game.prototype.onFocusLeaderboardName = function(el) {
    if (this.isIpad) {
      return this.answerOverlay.attr("data-keyboard", "true");
    }
  };

  Game.prototype.onBlurLeaderboardName = function(el) {
    return this.answerOverlay.attr("data-keyboard", "false");
  };

  Game.prototype.onChangeLeaderboardName = function(el) {
    var v;
    v = $(el.currentTarget).val();
    if (v !== "") {
      return $(".leaderboard-submit").removeClass("disabled");
    } else {
      return $(".leaderboard-submit").addClass("disabled");
    }
  };

  Game.prototype.onLeaderboardSubmit = function(el) {
    var e, l, name;
    ga('send', 'event', 'button', 'click', 'leaderboard-submit');
    e = $(el.currentTarget).parent().find(".leaderboard-name");
    name = e.val();
    if (name) {
      $("#cmhr-mc-game-conclusion").attr("data-state", this.ResultsSaving);
      l = new Leaderboard({
        name: name,
        score: this.score,
        time: this.totalTime
      });
      return l.save();
    } else {
      return el.preventDefault();
    }
  };

  Game.prototype.onLeaderboardSave = function(e) {
    Spine.trigger("window.location", "leaderboard/");
    return setTimeout((function(_this) {
      return function() {
        return $("#cmhr-mc-game-conclusion").attr("data-state", _this.ResultsOpen);
      };
    })(this), 2000);
  };

  Game.prototype.onLeaderboardError = function(e) {
    Spine.trigger("window.location", "leaderboard/");
    return setTimeout((function(_this) {
      return function() {
        return $("#cmhr-mc-game-conclusion").attr("data-state", _this.ResultsError);
      };
    })(this), 2000);
  };

  Game.prototype.onSocketControlStateChange = function(state) {
    if (state.station === this.station || state.station) {
      this.el.attr("data-multiplayer", state.controlled);
      return this.socketControlled = state.controlled;
    }
  };

  Game.prototype.onSocketAnswered = function(data) {
    var max, progress, score, station;
    this.log("onSocketAnswered :: " + data[0]['station']);
    station = data[0]['station'];
    score = data[0]['score'];
    max = this.getTotalQuestions() * this.scoreMaxQuestionPoints;
    progress = Math.round((score / max) * 100);
    $("#mc-game-score-" + station).html("" + score);
    return $("#mc-game-progress-" + station + " .bar").css("width", progress + "%");
  };

  Game.prototype.onSocketNext = function(state) {
    this.loadQuestion(this.currentQ + 1);
    return this.state(this.Question);
  };

  Game.prototype.onSocketScores = function(scores) {
    "Render game_conclusion_entries.coffee here.";
    var i, j, len, ranked, s, sorted;
    i = 1;
    ranked = [];
    for (j = 0, len = scores.length; j < len; j++) {
      s = scores[j];
      ranked.push({
        rank: "",
        me: this.station === i,
        station: i,
        score: s,
        progress: Math.round((s / (this.getTotalQuestions() * this.scoreMaxQuestionPoints)) * 100)
      });
      i++;
    }
    sorted = _.sortBy(ranked, function(o) {
      return -o.score;
    });
    sorted[0]['rank'] = "1st";
    sorted[1]['rank'] = "2nd";
    sorted[2]['rank'] = "3rd";
    sorted[3]['rank'] = "4th";
    sorted[4]['rank'] = "5th";
    sorted[5]['rank'] = "6th";
    $("#cmhr-mc-conclusion-leaderboard").html(require('views/game_conclusion_leaderboard')({
      sorted: sorted,
      station: this.station
    }));
    return Spine.trigger("translate");
  };

  Game.prototype.onSocketUpdateQueuedClients = function(clients) {
    "Update List of Active Stations";
    var i, j, results;
    results = [];
    for (i = j = 1; j < 7; i = ++j) {
      if (indexOf.call(clients.stations, i) >= 0) {
        if (i !== this.station) {
          results.push($("#mc-game-player-" + i).addClass("other-player"));
        } else {
          results.push($("#mc-game-player-" + i).removeClass("other-player"));
        }
      } else {
        results.push($("#mc-game-player-" + i).removeClass("other-player"));
      }
    }
    return results;
  };

  Game.prototype.getQuestion = function(i) {
    var o;
    o = this.getTotalQuestions() * this.questionOffset;
    return Question.all()[i + o];
  };

  Game.prototype.getTotalQuestions = function() {
    return Math.round(Question.count() / 2);
  };

  Game.prototype.handleAnswer = function(rank, skipped) {
    var max, points, progress;
    clearTimeout(this.answerTimeout);
    points = this.scoreAnswer(rank);
    max = this.getTotalQuestions() * this.scoreMaxQuestionPoints;
    progress = Math.round((this.score / max) * 100);
    progress = progress > 100 ? 100 : progress;
    this.setAnswer(rank, points);
    this.runTimer(false);
    $("#mc-game-score-single").html("" + this.score);
    $("#mc-game-progress-single .bar").css("width", progress + "%");
    Spine.trigger("game.update.score", this.score);
    if (this.socketControlled) {
      this.log("@socketControlled: " + this.socketControlled);
      Spine.trigger("game.multiplayer.answer", [
        {
          question: this.currentQ,
          last: this.currentQ + 1 === this.getTotalQuestions(),
          station: this.station,
          points: points,
          score: this.score
        }
      ]);
    }
    if (this.currentQ + 1 < this.getTotalQuestions()) {
      return this.state(this.Answer);
    } else {
      return this.state(this.AnswerLast);
    }
  };

  Game.prototype.loadQuestion = function(i) {
    "Load the Question with Index `i`";
    var question;
    ga('send', 'pageview', "/question/" + (i + 1));
    question = this.getQuestion(i);
    this.answers.html(require("views/game_answers")(question));
    this.image.attr("src", question.img);
    this.image.attr("data-en", question.media.altText.eng);
    this.image.attr("data-fr", question.media.altText.fra);
    $('#cmhr-mc-game-question-image-full').css("background-image", "url('" + question.img() + "')");
    Spine.trigger("nav.caption.update", question.media.creditLine);
    Spine.trigger("translate");
    this.answerSeconds = this.answerSecondsDefault;
    this.countdown.html(this.answerSecondsDefault);
    this.currentQ = i;
    return this.current.html(i + 1);
  };

  Game.prototype.reset = function() {
    "Reset Game for New Round";
    var i, j;
    this.answers.empty();
    this.answerSeconds = this.answerSecondsDefault;
    this.currentQ = 0;
    this.score = 0;
    this.startTime = 0;
    this.totalTime = 0;
    this.current.html(0 + 1);
    this.countdown.html(this.answerSecondsDefault);
    this.total.html(this.getTotalQuestions());
    this.leaderboardSubmit.addClass("disabled");
    this.leaderboardName.val("");
    this.conclusion.attr("data-state", this.ResultsOpen);
    for (i = j = 1; j < 7; i = ++j) {
      $("#mc-game-score-" + i).html("0");
      $("#mc-game-progress-" + i + " .bar").css("width", "0%");
    }
    $("#mc-game-score-single").html("0");
    return $("#mc-game-progress-single .bar").css("width", "0%");
  };

  Game.prototype.setAnswer = function(rank, points) {
    "Show the Answer Panel";
    var answer, best, graphic_list, graphic_score, i, img_path, j, k, l, len, len1, question, ref, yours;
    question = this.getQuestion(this.currentQ);
    best = null;
    yours = null;
    ref = question.answers;
    for (j = 0, len = ref.length; j < len; j++) {
      answer = ref[j];
      if (answer.rank === question.answers.length - 1) {
        best = answer;
      }
      if (answer.rank === rank) {
        yours = answer;
      }
    }
    graphic_score = parseInt(points / 20);
    graphic_list = [1, 2, 3, 4, 5];
    img_path = "/media/img/answer-leaf-";
    for (i = k = 0, len1 = graphic_list.length; k < len1; i = ++k) {
      l = graphic_list[i];
      if (graphic_score >= l) {
        graphic_list[i] = img_path + "active.png";
      } else {
        graphic_list[i] = img_path + "inactive.png";
      }
    }
    this.answerOverlay.html(require("views/game_answer")({
      "best": best,
      "message": rank,
      "points": points,
      "your": yours,
      "graphic_list": graphic_list
    }));
    return Spine.trigger("translate");
  };

  Game.prototype.setConclusion = function() {
    "Set the Contents of the Conclusion Panel";
    this.answerOverlay.html(require("views/game_conclusion")({
      "score": this.score
    }));
    Spine.trigger("translate");
    return Spine.trigger("game.get-scores");
  };

  Game.prototype.runTimer = function(state) {
    "Manage Question Timer";
    if (state) {
      this.timer.addClass("run");
      return this.answerInterval = setInterval((function(_this) {
        return function() {
          _this.answerSeconds = _this.answerSeconds - 1;
          return _this.countdown.html(_this.answerSeconds);
        };
      })(this), 1000);
    } else {
      clearInterval(this.answerInterval);
      return this.stop();
    }
  };

  Game.prototype.scoreAnswer = function(rank) {
    "Score the Selected Answer";
    var points;
    if (rank === -1) {
      return 0;
    } else if (rank > 2) {
      return 0;
    } else {
      points = this.scoreIncrement * (rank + 1);
      points = points + Math.round(this.scoreTimeWeight * (this.answerSeconds / this.answerSecondsDefault));
      this.score = this.score + points;
      return points;
    }
  };

  Game.prototype.stop = function() {
    "Kill the Timer on Game Stop";
    if (this.answerInterval !== null) {
      clearInterval(this.answerInterval);
    }
    if (this.answerTimeout !== null) {
      clearTimeout(this.answerTimeout);
    }
    if (this.stateTimeout !== null) {
      clearTimeout(this.stateTimeout);
    }
    return this.timer.removeClass("run");
  };

  Game.prototype.resize = function() {
    "On Resize, adjust position of single player score";
    var bar, h, ih, img, wh, ww, y;
    wh = $(window).height();
    ww = $(window).height();
    if ($("#cmhr-mc").hasClass("header-offset")) {
      wh = wh - 250;
    }
    ih = wh - $("#cmhr-desktop-header").height() - $("#cmhr-mc-nav").height();
    img = this.cellLeft.outerWidth() * 0.75;
    bar = this.leaderboardSingle.find(".player.single").height();
    h = ih - img;
    y = img + $("#cmhr-desktop-header").height();
    this.leaderboardSingle.find(".player.single").css("margin-top", y + ((h / 2) - 65));
    if (ww > 750) {
      return this.instructions.find('.pad').css("padding-top", (wh / 2) - (this.instructions.find('.pad').height() / 2));
    }
  };

  return Game;

})(Spine.Controller);

module.exports = Game;
