module.exports = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  helpers = helpers || Handlebars.helpers;
  var buffer = "", stack1, foundHelper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<section id=\"cmhr-mc-game-conclusion\" data-state=\"open\">\n    <div class=\"multi\">\n        <div class=\"cell left\">\n            <div class=\"pad\">\n                <h1 tabindex=\"0\" id=\"leaderboard-label\" data-msg=\"title\"></h1>\n                <h2 tabindex=\"0\" data-msg=\"label.multiplayer-conclusion\"></h2>\n                <hr>\n\n                <div class=\"wrapper\" aria-labelledby=\"leaderboard-label\">\n                    <div id=\"cmhr-mc-leaderboard-frame\" class=\"scroller\">\n                        <table id=\"cmhr-mc-conclusion-leaderboard\"></table>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"cell right\">\n            <div class=\"pad\">\n                <div class=\"flag\">\n                    <!-- <span aria-hidden=\"true\" class=\"label\"><bold>2</bold>nd</span>\n                    <span aria-hidden=\"true\" class=\"label\" data-msg=\"label.place\" data-place></span> -->\n                    <span class=\"aria-offscreen\" aria-label=\"Player ";
  foundHelper = helpers.station;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.station; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "\"></span>\n                </div>\n                <h1 id=\"conclusion-points\" data-msg=\"label.conclusion-points\" data-points=\"";
  foundHelper = helpers.score;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.score; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "\"></h1>\n\n                <i class=\"leaf-icon single\"></i>\n                <h1 tabindex=\"1\" id=\"conclusion-points\" data-msg=\"label.conclusion-points\" data-points=\"";
  foundHelper = helpers.score;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.score; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "\" class=\"single\"></h1>\n                <h2 aria-hidden=\"true\" data-msg=\"label.save\"></h2>\n                <h2 aria-hidden=\"true\" data-msg=\"label.save-error\"></h2>\n                <div class=\"submit-form container\">\n                    <label for=\"cmhr-mc-leaderboard-submit-name\" tabindex=\"2\" id=\"name-input-label\" data-msg=\"label.leaderboard.add\"></label>\n                    <input tabindex=\"0\" type=\"text\" class=\"leaderboard-name\">\n                    <a tabindex=\"0\" data-msg=\"label.submit\" class=\"btn btn-primary leaderboard-submit disabled\" role=\"button\"></a>\n                    <a tabindex=\"0\" href=\"#/en/leaderboard/\" data-msg=\"label.leaderboard.no-thanks\" class=\"i18n btn btn-primary float-right dark\" role=\"button\"></a>\n                </div>\n            </div>\n            <div class=\"triangle\"></div>\n        </div>\n    </div>\n\n    <div class=\"single\">\n        <div class=\"pad\">\n            <i class=\"leaf-icon single\"></i>\n            <h1 tabindex=\"0\" id=\"conclusion-points\" data-msg=\"label.conclusion-points\" data-points=\"";
  foundHelper = helpers.score;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.score; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "\" class=\"single\"></h1>\n            <h2 aria-hidden=\"true\" data-msg=\"label.save\"></h2>\n            <h2 aria-hidden=\"true\" data-msg=\"label.save-error\"></h2>\n            <div class=\"submit-form container\">\n                <label for=\"cmhr-mc-leaderboard-submit-name\" tabindex=\"2\" id=\"name-input-label\" data-msg=\"label.leaderboard.add\"></label>\n                <input tabindex=\"0\" type=\"text\" class=\"leaderboard-name\">\n                <a tabindex=\"0\" data-msg=\"label.submit\" class=\"btn btn-primary leaderboard-submit disabled\" role=\"button\"></a>\n                <a tabindex=\"0\" href=\"#/en/leaderboard/\" data-msg=\"label.leaderboard.no-thanks\" class=\"i18n btn btn-primary float-right\" role=\"button\"></a>\n            </div>\n        </div>\n    </div>\n</section>\n";
  return buffer;});