var App, Attract, Config, Game, Intro, Leaderboard, Loading, Nav, Question, Socket,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty,
  indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

Attract = require("controllers/attract");

Game = require("controllers/game");

Intro = require("controllers/intro");

Leaderboard = require("controllers/leaderboard");

Loading = require("controllers/loading");

Nav = require("controllers/nav");

Socket = require("controllers/socket");

Config = require("models/config");

Question = require("models/question");

App = (function(superClass) {
  "# POI Application Controller";
  var modelsLoaded, modelsTotal;

  extend(App, superClass);

  App.prototype.language = "";

  App.prototype.current = "loading";

  App.prototype.Attract = "attract";

  App.prototype.Game = "game";

  App.prototype.Intro = "intro";

  App.prototype.Leaderboard = "leaderboard";

  App.prototype.Loading = "loading";

  App.prototype.station = -1;

  App.prototype.gallery = false;

  modelsTotal = 2;

  modelsLoaded = 0;

  App.prototype.host = {
    en: "https://humanrights.ca/",
    fr: "https://droitsdelapersonne.ca/"
  };

  function App(cfg) {
    this.translate = bind(this.translate, this);
    this.triggerTranslation = bind(this.triggerTranslation, this);
    this.onWindowUnload = bind(this.onWindowUnload, this);
    this.onWindowLocation = bind(this.onWindowLocation, this);
    this.onSocketGameStart = bind(this.onSocketGameStart, this);
    this.terminate = bind(this.terminate, this);
    this.error = bind(this.error, this);
    this.loaded = bind(this.loaded, this);
    this.stateLeaderboard = bind(this.stateLeaderboard, this);
    this.stateGame = bind(this.stateGame, this);
    this.stateIntro = bind(this.stateIntro, this);
    this.stateAttract = bind(this.stateAttract, this);
    this.log("App::init");
    this.routes({
      "/": this.stateAttract,
      "/:lang/intro/": this.stateIntro,
      "/:lang/game/": this.stateGame,
      "/:lang/leaderboard/": this.stateLeaderboard
    });
    App.__super__.constructor.apply(this, arguments);
    if ("station" in cfg) {
      this.station = cfg.station;
    }
    if ("gallery" in cfg) {
      this.gallery = cfg.gallery;
    }
    this.loading = new Loading();
    Spine.bind("translate", this.triggerTranslation);
    Spine.bind("socket.game.start", this.onSocketGameStart);
    Spine.bind("window.location", this.onWindowLocation);
    Question.bind("refresh change update destroy", this.loaded);
    Question.bind("ajaxError", this.error);
    Config.bind("refresh change update destroy", this.loaded);
    Config.bind("ajaxError", this.error);
    if (this.gallery) {
      window.onbeforeunload = this.onWindowUnload;
    }
    Question.fetch();
    Config.fetch();
  }

  App.prototype.stateAttract = function() {
    "Set Application State to Attract Mode";
    if (this.current !== this.Attract) {
      ga('set', {
        page: "/" + this.language + "/",
        title: "Magna Carta: Attract (" + this.language + ")"
      });
      this.nav.state(this.nav.Closed);
      this.game.state(this.game.Closed);
      this.intro.state(this.intro.Closed);
      this.leaderboard.state(this.leaderboard.Closed);
      setTimeout((function(_this) {
        return function() {
          return _this.attract.state(_this.attract.Open);
        };
      })(this), 1000);
      return this.current = this.Attract;
    }
  };

  App.prototype.stateIntro = function(path) {
    "Set Application State to Intro";
    if (this.current !== this.Intro) {
      ga('set', {
        page: "/" + this.language + "/intro/",
        title: "Magna Carta: Intro (" + this.intro + ")"
      });
      ga('send', 'pageview', '/intro/');
      this.attract.state(this.attract.Closed);
      this.game.state(this.game.Closed);
      this.nav.state(this.nav.Open);
      this.leaderboard.state(this.leaderboard.Closed);
      setTimeout((function(_this) {
        return function() {
          return _this.intro.state(_this.intro.Open);
        };
      })(this), 1000);
      this.translate(path.lang);
      return this.current = this.Intro;
    }
  };

  App.prototype.stateGame = function(path) {
    "Set Application State to Game";
    if (this.current !== this.Game) {
      ga('set', {
        page: "/" + this.language + "/game/",
        title: "Magna Carta: Game (" + this.language + ")"
      });
      this.attract.state(this.attract.Closed);
      this.intro.state(this.intro.Closed);
      this.nav.state(this.nav.Open);
      this.leaderboard.state(this.leaderboard.Closed);
      setTimeout((function(_this) {
        return function() {
          return _this.game.state(_this.game.Open);
        };
      })(this), 1000);
      this.translate(path.lang);
      return this.current = this.Game;
    }
  };

  App.prototype.stateLeaderboard = function(path) {
    "Set Application State to Leaderboard";
    if (this.current !== this.Leaderboard) {
      ga('set', {
        page: "/" + this.language + "/leaderboard/",
        title: "Magna Carta: Leaderboard (" + this.language + ")"
      });
      ga('send', 'pageview', '/leaderboard/');
      this.attract.state(this.attract.Closed);
      this.game.state(this.game.Closed);
      this.intro.state(this.intro.Closed);
      this.nav.state(this.nav.Open);
      setTimeout((function(_this) {
        return function() {
          return _this.leaderboard.state(_this.leaderboard.Open);
        };
      })(this), 1000);
      this.translate(path.lang);
      return this.current = this.Leaderboard;
    }
  };

  App.prototype.loaded = function() {
    "Event Handler for All Content Loaded and App Ready to Init";
    var l;
    modelsLoaded++;
    if (modelsLoaded === modelsTotal) {
      this.attract = new Attract(this.station);
      this.game = new Game(this.station);
      this.intro = new Intro(this.station, this.gallery);
      this.leaderboard = new Leaderboard(this.station);
      this.nav = new Nav(this.station);
      if (this.gallery) {
        this.socket = new Socket();
      }
      l = $("html").attr("lang") ? $("html").attr("lang") : "en";
      this.translate(l);
      return setTimeout((function(_this) {
        return function() {
          Spine.Route.setup();
          if (!window.location.hash) {
            _this.navigate("/");
          }
          return _this.loading.inactive();
        };
      })(this), 1000);
    }
  };

  App.prototype.error = function(msg) {
    "Event Handler for Content Loading Error";
    return this.log("Error");
  };

  App.prototype.terminate = function() {
    "Clean-up on Termination";
    return Spine.trigger("game.multiplayer.leave", this.station);
  };

  App.prototype.onSocketGameStart = function(msg) {
    var ref;
    if (ref = this.station, indexOf.call(msg.queue, ref) >= 0) {
      return this.navigate("/" + this.language + "/game/");
    }
  };

  App.prototype.onWindowLocation = function(path) {
    "Change the Window Location";
    return window.location.hash = "/" + this.language + "/" + path;
  };

  App.prototype.onWindowUnload = function(e) {
    this.terminate();
    return null;
  };

  App.prototype.triggerTranslation = function(lang) {
    var current, to;
    if (lang == null) {
      lang = null;
    }
    "Event Handler to Manually `trigger` content translation.";
    current = lang === "en" ? "fr" : "en";
    to = lang ? lang : this.language;
    this.translate(to);
    return window.location.hash = window.location.hash.replace(current, to);
  };

  App.prototype.translate = function(lang) {
    "Update Language Strings on all i18n Strings and update links";
    var current, i, k, key, len, lng, msg, ref, txt, v, vars;
    this.language = lang;
    current = lang === "en" ? "fr" : "en";
    lng = lang === "en" ? "eng" : "fra";
    $("html").attr("lang", lang);
    $("#cmhr-mc-logo").attr("href", this.host[lang]);
    ref = Config.all()[0].messages;
    for (key in ref) {
      msg = ref[key];
      txt = msg[lng];
      if (txt) {
        vars = msg[lng].match(/<(.*?)>/g);
      }
      if (vars) {
        for (i = 0, len = vars.length; i < len; i++) {
          v = vars[i];
          k = v.replace("<", "").replace(">", "");
          txt = txt.replace(v, $("[data-msg='" + key + "']").attr("data-" + k));
        }
      }
      if ($("[data-msg='" + key + "']").hasClass('i18n-social')) {
        $("[data-msg='" + key + "']").attr("href", txt);
      } else {
        $("[data-msg='" + key + "']").html(txt);
      }
    }
    _.each($("[data-en],[data-fr]"), (function(_this) {
      return function(str) {
        var e;
        e = $(str);
        if (e.is("img")) {
          return e.attr("alt", $(str).attr("data-" + lang));
        } else {
          return e.html($(str).attr("data-" + lang));
        }
      };
    })(this));
    return _.each($("a.i18n"), (function(_this) {
      return function(a) {
        var href;
        href = $(a).attr("href");
        return $(a).attr("href", href.replace(current, lang));
      };
    })(this));
  };

  return App;

})(Spine.Controller);

module.exports = App;
