var Nav,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Nav = (function(superClass) {
  "Application Loading Controller";
  extend(Nav, superClass);

  Nav.prototype.el = $("#cmhr-mc-nav");

  Nav.prototype.elements = {
    "#cmhr-mc-nav-attribution": "attribution"
  };

  Nav.prototype.events = {
    "click .language-select": "onLanguageSelect",
    "click #cmhr-mc-restart": "onRestart",
    "click #cmhr-mc-restart-small": "onRestart"
  };

  Nav.prototype.station = -1;

  Nav.prototype.Open = "open";

  Nav.prototype.Closed = "closed";

  function Nav(station) {
    this.resize = bind(this.resize, this);
    this.onUpdateHide = bind(this.onUpdateHide, this);
    this.onUpdateCaption = bind(this.onUpdateCaption, this);
    this.onRestart = bind(this.onRestart, this);
    this.onLanguageSelect = bind(this.onLanguageSelect, this);
    this.state = bind(this.state, this);
    "Render View and Init Elements and Events";
    this.station = station;
    this.el.html(require('views/nav'));
    Nav.__super__.constructor.apply(this, arguments);
    this.attribution.hide();
    Spine.bind("nav.caption.update", this.onUpdateCaption);
    Spine.bind("nav.caption.hide", this.onUpdateHide);
  }

  Nav.prototype.state = function(to) {
    "Set Component State";
    this.el.attr("data-state", to);
    switch (to) {
      case this.Open:
        return this.el.attr("aria-hidden", "false");
      case this.Closed:
        return this.el.attr("aria-hidden", "true");
    }
  };

  Nav.prototype.onLanguageSelect = function(e) {
    var elem;
    elem = $(e.currentTarget);
    Spine.trigger("translate", elem.attr("data-lang"));
    return ga('send', 'event', 'button', 'click', 'lang-selector-' + elem.attr("data-lang"));
  };

  Nav.prototype.onRestart = function() {
    return ga('send', 'event', 'button', 'click', 'restart');
  };

  Nav.prototype.onUpdateCaption = function(caption) {
    this.attribution.show();
    this.attribution.attr("data-en", caption.eng);
    this.attribution.attr("data-fr", caption.fra);
    $("#cmhr-mc-nav-mobile-attribution").show();
    $("#cmhr-mc-nav-mobile-attribution").attr("data-en", caption.eng);
    return $("#cmhr-mc-nav-mobile-attribution").attr("data-fr", caption.fra);
  };

  Nav.prototype.onUpdateHide = function(caption) {
    $("#cmhr-mc-nav-mobile-attribution").hide();
    return this.attribution.hide();
  };

  Nav.prototype.resize = function() {
    var ww;
    ww = $(window).width();
    if (ww > 750) {
      return this.attribution.show();
    } else {
      return $("#cmhr-mc-nav-mobile-attribution").show();
    }
  };

  return Nav;

})(Spine.Controller);

module.exports = Nav;
