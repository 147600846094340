var Question,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Question = (function(superClass) {
  extend(Question, superClass);

  function Question() {
    this.img = bind(this.img, this);
    return Question.__super__.constructor.apply(this, arguments);
  }

  Question.configure("Question", "id");

  Question.extend(Spine.Model.Ajax);

  Question.url = "/api/questions.json";

  Question.prototype.img = function() {
    var first, last, ref;
    ref = this.media.fullImage.url.split('/'), first = ref[0], last = ref[ref.length - 1];
    return "/media/content/" + last;
  };

  Question.fromJSON = function(objects) {
    objects = objects.questions;
    return Question.__super__.constructor.fromJSON.call(this, objects);
  };

  return Question;

})(Spine.Model);

module.exports = Question;
