module.exports = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  helpers = helpers || Handlebars.helpers;
  var buffer = "", stack1, foundHelper, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "";
  buffer += "\n                <img src=\"";
  depth0 = typeof depth0 === functionType ? depth0() : depth0;
  buffer += escapeExpression(depth0) + "\">\n            ";
  return buffer;}

function program3(depth0,data) {
  
  var stack1, foundHelper;
  foundHelper = helpers.message;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.message; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  return escapeExpression(stack1);}

function program5(depth0,data) {
  
  
  return "0";}

function program7(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n            <label  data-msg=\"label.your-answer\"></label>\n            <p  class=\"your-answer\" data-en=\"";
  stack1 = depth0.your;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.answer;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.eng;
  stack1 = typeof stack1 === functionType ? stack1() : stack1;
  buffer += escapeExpression(stack1) + "\" data-fr=\"";
  stack1 = depth0.your;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.answer;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.fra;
  stack1 = typeof stack1 === functionType ? stack1() : stack1;
  buffer += escapeExpression(stack1) + "\"></p>\n            ";
  return buffer;}

function program9(depth0,data) {
  
  
  return "\n            <label  data-msg=\"label.no-answer-label\"></label>\n            <p  class=\"your-answer\" data-msg=\"label.no-answer\"></p>\n            ";}

  buffer += "\n<div class=\"status-top-wrapper\">\n    <div class=\"left\">\n        <div class=\"points\" aria-hidden=\"true\">\n            ";
  stack1 = depth0.graphic_list;
  stack1 = helpers.each.call(depth0, stack1, {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n        <div style=\"clear: both;\"></div>\n        <h1 tabindex=\"2\" data-msg=\"game.result.";
  stack1 = depth0.your;
  stack1 = helpers['if'].call(depth0, stack1, {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\"></h1>\n        <h2 tabindex=\"3\" data-msg=\"label.earned-points\" data-points=\"";
  foundHelper = helpers.points;
  if (foundHelper) { stack1 = foundHelper.call(depth0, {hash:{}}); }
  else { stack1 = depth0.points; stack1 = typeof stack1 === functionType ? stack1() : stack1; }
  buffer += escapeExpression(stack1) + "\"></h2>\n    </div>\n\n    <div class=\"right\" role=\"navigation\">\n        <a tabindex=\"0\" role=\"button\" id=\"cmhr-mc-game-next\" data-msg=\"label.next-question\" class=\"btn btn-primary\"></a>\n        <a tabindex=\"0\" role=\"button\" id=\"cmhr-mc-game-results\" data-msg=\"label.how-did-i-do\" class=\"btn btn-primary\"></a>\n    </div>\n    <div style=\"clear: both;\"></div>\n</div>\n\n<div class=\"fill\" role=\"complementary\">\n    <div class=\"answer-wrapper\">\n        <div class=\"answer your\">\n            ";
  stack1 = depth0.your;
  stack1 = helpers['if'].call(depth0, stack1, {hash:{},inverse:self.program(9, program9, data),fn:self.program(7, program7, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        </div>\n        <div class=\"answer best\">\n            <img src=\"/media/img/shield-small.png\" aria-hidden=\"true\" />\n            <label  data-msg=\"label.best-answer\"></label>\n            <p  data-en=\"";
  stack1 = depth0.best;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.answer;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.eng;
  stack1 = typeof stack1 === functionType ? stack1() : stack1;
  buffer += escapeExpression(stack1) + "\" data-fr=\"";
  stack1 = depth0.best;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.answer;
  stack1 = stack1 == null || stack1 === false ? stack1 : stack1.fra;
  stack1 = typeof stack1 === functionType ? stack1() : stack1;
  buffer += escapeExpression(stack1) + "\"></p>\n        </div>\n    </div>\n</div>\n\n<div class=\"leaf-bg\"></div>\n<div class=\"parallelogram\"></div>\n";
  return buffer;});