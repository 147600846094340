var Loading,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; },
  extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
  hasProp = {}.hasOwnProperty;

Loading = (function(superClass) {
  "Application Loading Controller";
  extend(Loading, superClass);

  Loading.prototype.el = $("#cmhr-mc-loading");

  function Loading() {
    this.inactive = bind(this.inactive, this);
    this.active = bind(this.active, this);
    this.el.html(require('views/loading'));
    Loading.__super__.constructor.apply(this, arguments);
    this.active();
  }

  Loading.prototype.active = function() {
    "Start Loading State";
    return this.el.show();
  };

  Loading.prototype.inactive = function() {
    "End Loading State";
    return this.el.hide();
  };

  return Loading;

})(Spine.Controller);

module.exports = Loading;
